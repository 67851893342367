@import "./Common.scss";

.GiftList {
  display: grid;
  display: -moz-grid;
  display: -ms-grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  padding-bottom: 100px;
  width: 80%;
  justify-items: center;
  align-items: center;
  margin: auto;
}

.GiftPile {
  background-color: $grey-color;
  min-height: 70vh;
  width: 100%;
  padding: 50px 0px 160px 0px;
  justify-content: center;
  align-items: center;
}
