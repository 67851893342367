@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: Comfortaa-Regular;
  src: url("../fonts/Comfortaa-Regular.ttf");
}

$white-color: #eeeded;
$accent-color: #8f384d;
$grey-color: #bdbdd3;
$dark-grey-color: #8f8fa1;
$title-color: #dd9866;
$base-color: #5c6e91;

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

input {
  outline: none;
  border: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
  -webkit-appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input::-webkit-input-placeholder {
  color: $grey-color;
}
input:-moz-placeholder {
  color: $grey-color;
}
input::-moz-placeholder {
  color: $grey-color;
}
input:-ms-input-placeholder {
  color: $grey-color;
}

textarea::-webkit-input-placeholder {
  color: $grey-color;
}
textarea:-moz-placeholder {
  color: $grey-color;
}
textarea::-moz-placeholder {
  color: $grey-color;
}
textarea:-ms-input-placeholder {
  color: $grey-color;
}

.buttonContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 10px;
}

.bigButton {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 150px;
  height: 62px;
  border-radius: 31px;

  font-family: Comfortaa-Regular;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  position: relative;
  z-index: 1;
}

.bigButton::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 31px;
  background-color: $accent-color;
  pointer-events: none;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.bigButton:hover:before {
  background-color: $accent-color;
  width: calc(100% + 20px);
}

.bigButton:disabled,
.bigButton[disabled] {
  background-color: $accent-color;
  opacity: 0.5;
}

.smallButton {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 100px;
  height: 21px;
  border-radius: 10px;

  font-family: Comfortaa-Regular;
  font-size: 12px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  position: relative;
  z-index: 1;
}

.smallButton::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 31px;
  background-color: $accent-color;
  pointer-events: none;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.smallButton:hover:before {
  background-color: $accent-color;
  width: calc(100% + 20px);
}

.smallButton:disabled,
.smallButton[disabled] {
  background-color: $accent-color;
  opacity: 0.5;
}

.title {
  display: block;
  font-family: Comfortaa-Regular;
  font-size: 30px;
  color: $title-color;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 49px;
}

.customPopover {
  font-family: Comfortaa-Regular;
  background-color: $white-color;
}

.customPopoverTitle {
  background-color: $white-color;
  color: $dark-grey-color;
}

.customPopoverContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
