@import "./Common.scss";

.GiftWrapping {
  width: 100px;
  height: 100px;
  border-radius: 5%;
  background-size: cover;
  border-color: $accent-color;
  border-style: solid;
}
