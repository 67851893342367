@import "./Common.scss";

.Order {
  overflow: auto;
  white-space: nowrap;
  overflow-x: auto;
  max-width: 100%;
  text-align: center;
}

.OrderHeader {
  padding-bottom: 15px !important;
}

.GameOrder {
  background-color: $white-color;
  height: 15%;
  padding: 15px;
}

.EventHeader {
  padding-top: 20px;
}
