@import "./Common.scss";

.waitingRoom {
  background-color: $white-color;
  min-height: 100vh;
  width: 100%;
  padding: 50px 0px 160px 0px;
  justify-content: center;
  align-items: center;
}

.elephantAvatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-size: cover;
  border-color: $accent-color;
  border-style: solid;
  align-self: center;
}

.playerName {
  font-family: Comfortaa-Regular;
  color: $dark-grey-color;
  display: block;
  margin-top: 5px;
  align-self: center;
}

.avatar {
  vertical-align: top;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.playerList {
  display: grid;
  display: -moz-grid;
  display: -ms-grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  padding-bottom: 100px;
  width: 80%;
  justify-items: center;
  align-items: center;
  margin: auto;
}

.joinLink {
  font-family: Comfortaa-Regular;
  color: $accent-color;
  text-align: center;
  padding-bottom: 40px;
}
