@import "./Common.scss";

.playerInfo {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  position: relative;
  background-color: $white-color;
}

.playerInfoForm {
  width: 550px;
  padding: 50px 0px 160px 0px;
}

.wrapInput {
  width: 100%;
  background-color: #fff;
  border-radius: 31px;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
}

.wrapRadio {
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
}

.wrapRadio p {
  font-family: Comfortaa-Regular;
  margin-bottom: 5px;
  color: $dark-grey-color;
  font-size: 18px;
}

.playerInput {
  position: relative;
  display: block;
  width: 100%;
  background: #fff;
  border-radius: 31px;
  font-family: Comfortaa-Regular;
  font-size: 18px;
  color: $dark-grey-color;
  line-height: 1.2;
}

input.playerInput {
  height: 62px;
  padding: 0 35px 0 35px;
}

textarea.playerInput {
  min-height: 100px;
  padding: 19px 35px 0 35px;
}

.focusPlayerInput {
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 31px;
  background-color: #fff;
  pointer-events: none;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.playerInput:focus + .focusPlayerInput {
  width: calc(100% + 20px);
}

.m-r-6 {
  margin-right: 6px;
}

.wrapSelector input {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.wrapSelector input:active + .wrapLabel {
  opacity: 0.9;
}

.wrapSelector input:checked + .wrapLabel {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}

.wrapLabel {
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  border-radius: 5px;
  margin-right: 5px;
  width: 100px;
  height: 70px;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  -webkit-filter: brightness(1.8) grayscale(1) opacity(0.7);
  -moz-filter: brightness(1.8) grayscale(1) opacity(0.7);
  filter: brightness(1.8) grayscale(1) opacity(0.7);
}
.wrapLabel:hover {
  -webkit-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  -moz-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  filter: brightness(1.2) grayscale(0.5) opacity(0.9);
}

.wikiLink {
  color: $base-color;
  font-family: Comfortaa-Regular;
  font-size: 15px;
  text-transform: capitalize;
}

// .santa {
//   background-image: url(/wrappings/santa.png);
// }
// .red-tartan {
//   background-image: url(/public/wrappings/red-tartan.jpg);
// }

// .craft-paper {
//   background-image: url(../../public/wrappings/craft-paper.png);
// }

// .franzen {
//   background-image: url(../../public/wrappings/franzen.png);
// }
// .popsicles {
//   background-image: url(../../public/wrappings/popsicles.png);
// }
