@import "./Common.scss";

.Footer {
  background-color: $base-color;
  width: 100%;
  text-align: center;
}

.Footer p {
  font-family: Comfortaa-Regular;
  padding: 10px;
  color: $grey-color;
}

.Footer span {
  color: #e25555;
}
