@import "./Common.scss";

.Endgame {
  background-color: $white-color;
  min-height: 100vh;
  width: 100%;
  padding: 50px 0px 160px 0px;
  justify-content: center;
  align-items: center;
}
