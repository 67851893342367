@import "./Common.scss";

.ClaimedGift {
  display: inline-block;

  padding: 8px 10px;
  text-decoration: none;
  color: $grey-color;
}

.orderAvatar {
  opacity: 0.5;
}

.swapButton {
  margin: 5px;
}
